<template>
  <div
    @click="closeActionBar()"
    :class="['overlay', { active: activeBar }]"
  ></div>
  <div class="wrapper">
    <div class="container">
      <div class="logo"><img src="../assets/images/logo.svg" alt="" /></div>

      <div class="content">
        <div class="add-box">
          <button @click="openActionBar('add', 'dish')" class="btn btn-orange">
            Новое блюдо
          </button>
          <button
            @click="openActionBar('add', 'garnish')"
            class="btn btn-orange"
          >
            Новый гарнир
          </button>
        </div>

        <div :class="['action-bar', { active: activeBar }]">
          <div @click="closeActionBar()" class="close-bar">
            <img src="../assets/images/close.svg" alt="" />
          </div>
          <h2 class="title-head">
            <template v-if="!updateForm">
              Добавить
              <template v-if="postType === 'dish'">блюдо</template>
              <template v-if="postType === 'garnish'">гарнир</template>
            </template>
            <template v-else>Редактировать</template>
          </h2>
          <form
            v-if="postType === 'dish'"
            @submit.prevent="
              updateForm === false ? sendDish() : updateDish(formData.id)
            "
            class="form"
          >
            <div class="form-item" :class="{ 'form-error': errors }">
              <label for="dish-title">Название</label>
              <input id="dish-title" v-model="formData.title" type="text" />
            </div>
            <div class="form-item">
              <label for="dish-title-ru">Название (Ru)</label>
              <input
                id="dish-title-ru"
                v-model="formData.title_ru"
                type="text"
              />
            </div>
            <div class="form-item">
              <label for="dish-price">Цена (€)</label>
              <input
                id="dish-price"
                v-model.number="formData.price"
                type="text"
              />
            </div>
            <div class="form-select">
              <label>Дни недели</label>
              <div class="custom-checkbox">
                <div
                  v-for="day in categoryDays"
                  :key="day.id"
                  class="checkbox-item"
                >
                  <input
                    class="checkbox"
                    type="checkbox"
                    :id="'day' + day.id"
                    v-bind:value="day.id"
                    v-model="valueDays"
                    :checked="valueDays[day.id]"
                  />
                  <label :for="'day' + day.id">{{ day.shortName }}</label>
                </div>
              </div>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-green">Сохранить</button>
            </div>
          </form>

          <form
            v-if="postType === 'garnish'"
            @submit.prevent="
              updateForm === false ? sendGarnish() : updateGarnish(formData.id)
            "
            class="form"
          >
            <div class="form-item">
              <label for="garnish-title">Название</label>
              <input id="garnish-title" v-model="formData.title" type="text" />
            </div>
            <div class="form-item">
              <label ffor="garnish-title-ru">Название (Ru)</label>
              <input
                id="garnish-title-ru"
                v-model="formData.title_ru"
                type="text"
              />
            </div>
            <div class="form-select">
              <label>Дни недели</label>
              <div class="custom-checkbox">
                <div
                  v-for="day in categoryDays"
                  :key="day.id"
                  class="checkbox-item"
                >
                  <input
                    class="checkbox"
                    type="checkbox"
                    :id="'day' + day.id"
                    v-bind:value="day.id"
                    v-model="valueDays"
                    :checked="valueDays[day.id]"
                  />
                  <label :for="'day' + day.id">{{ day.shortName }}</label>
                </div>
              </div>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-green">Сохранить</button>
            </div>
          </form>
        </div>
        <div class="content">
          <ul class="filter-head">
            <li
              @click="sortByDay('')"
              :class="{
                active: activeClassDay === null || activeClassDay === undefined,
              }"
            >
              Все дни
            </li>
            <li
              v-for="(day, i) in days"
              :key="i"
              @click="sortByDay(day.fullName, i)"
              :class="{ active: i === activeClassDay }"
            >
              {{ day.shortName }}
            </li>
          </ul>

          <div class="content-items">
            <div class="item">
              <div class="dishes-box">
                <h2 class="title-box-small">Основные блюда:</h2>
                <div
                  v-for="dish in filteredDishes"
                  :key="dish.id"
                  class="dishes-item"
                >
                  <div class="dishes-wrap flex justify-space-between">
                    <div class="info">
                      <div class="title">{{ dish.title }}</div>
                      <div class="small-title">{{ dish.title_ru }}</div>
                    </div>
                    <div class="price">{{ dish.price }}€</div>
                  </div>
                  <div v-if="dish.days" class="days">
                    <template v-if="dish.days.length">
                      <span v-for="day in dish.days" :key="day.id">
                        <span class="day-tag">{{ day.title }}</span>
                      </span>
                    </template>
                    <span v-else> Дни не указаны </span>
                  </div>
                  <div class="actions">
                    <button
                      @click="openActionBar('edit', 'dish', dish)"
                      class="btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                          fill="rgba(255,255,255,0.7)"
                        />
                      </svg>
                    </button>
                    <button @click="deleteDish(dish.id)" class="btn btn-delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                          fill="rgba(255,255,255,0.7)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div v-if="!filteredDishes.length" class="no-data">
                  Нет данных
                </div>
              </div>
              <div class="garnish-box">
                <h2 class="title-box-small">Гарнир:</h2>
                <div
                  v-for="garnish in filteredGarnishes"
                  :key="garnish.id"
                  class="dishes-item"
                >
                  <div class="dishes-wrap flex justify-space-between">
                    <div class="info">
                      <div class="title">{{ garnish.title }}</div>
                      <div class="small-title">{{ garnish.title_ru }}</div>
                    </div>
                  </div>
                  <div v-if="garnish.days" class="days">
                    <template v-if="garnish.days.length">
                      <span v-for="day in garnish.days" :key="day.id">
                        <span class="day-tag">{{ day.title }}</span>
                      </span>
                    </template>
                    <span v-if="!garnish.days.length"> Дни не указаны </span>
                  </div>
                  <div class="actions">
                    <button
                      @click="openActionBar('edit', 'garnish', garnish)"
                      class="btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M8.686 4l2.607-2.607a1 1 0 0 1 1.414 0L15.314 4H19a1 1 0 0 1 1 1v3.686l2.607 2.607a1 1 0 0 1 0 1.414L20 15.314V19a1 1 0 0 1-1 1h-3.686l-2.607 2.607a1 1 0 0 1-1.414 0L8.686 20H5a1 1 0 0 1-1-1v-3.686l-2.607-2.607a1 1 0 0 1 0-1.414L4 8.686V5a1 1 0 0 1 1-1h3.686zM6 6v3.515L3.515 12 6 14.485V18h3.515L12 20.485 14.485 18H18v-3.515L20.485 12 18 9.515V6h-3.515L12 3.515 9.515 6H6zm6 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                          fill="rgba(255,255,255,0.7)"
                        />
                      </svg>
                    </button>
                    <button
                      @click="deleteGarnish(garnish.id)"
                      class="btn btn-delete"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                          fill="rgba(255,255,255,0.7)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div v-if="!filteredGarnishes.length" class="no-data">
                  Нет данных
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "Main",
  setup() {
    const toast = useToast();

    return { toast };
  },

  data: () => ({
    url: "//api.simpleresto.ee",
    // url: 'http://localhost:1337',
    dishes: [],
    garnishes: [],
    updateForm: false,
    days: [
      {
        fullName: "Понедельник",
        shortName: "Пн",
      },
      {
        fullName: "Вторник",
        shortName: "Вт",
      },
      {
        fullName: "Среда",
        shortName: "Ср",
      },
      {
        fullName: "Четверг",
        shortName: "Чт",
      },
      {
        fullName: "Пятница",
        shortName: "Пт",
      },
      {
        fullName: "Суббота",
        shortName: "Сб",
      },
      {
        fullName: "Воскресенье",
        shortName: "Вс",
      },
    ],
    categoryDays: [],
    activeBar: false,
    activeClassDay: null,
    valueDays: [],
    checkedDay: false,
    formData: {},
    postType: "",
    errorsInput: false,
    sortedDishes: [],
    sortedGarnishes: [],
    optionToast: {
      timeout: 3000,
      position: "top-left",
    },
  }),

  mounted() {
    this.getDishes();
    this.getGarnishes();
    this.axios
      .get(`${this.url}/days`)
      .then((res) => {
        this.categoryDays = res.data;
        this.categoryDays.map((item) => {
          this.days.forEach((day) => {
            if (day.fullName === item.title) {
              item.shortName = day.shortName;
            }
          });
        });
      })
      .catch((error) => {
        this.toast.error(error.request.statusText, this.optionToast);
      });
  },

  methods: {
    getDishes() {
      this.axios
        .get(`${this.url}/dishes`)
        .then((res) => {
          this.dishes = res.data;
          this.sortedDishes = this.dishes;
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    getGarnishes() {
      this.axios
        .get(`${this.url}/garnishes`)
        .then((res) => {
          this.garnishes = res.data;
          this.sortedGarnishes = this.garnishes;
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendDish() {
      this.formData.days = this.valueDays;
      this.axios
        .post(`${this.url}/dishes/`, this.formData)
        .then(() => {
          this.toast.success("Блюдо успешно добавлено", {
            timeout: 3000,
            position: "top-left",
          });
          this.getDishes();
          this.closeActionBar();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateDish(id) {
      this.formData.days = this.valueDays;
      this.axios
        .put(`${this.url}/dishes/${id}`, this.formData)
        .then(() => {
          this.toast.success("Блюдо успешно изменено", {
            timeout: 3000,
            position: "top-left",
          });

          this.getDishes();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteDish(id) {
      this.axios
        .delete(`${this.url}/dishes/${id}`)
        .then(() => {
          this.toast.success("Блюдо успешно удалено", {
            timeout: 3000,
            position: "top-left",
          });
          this.getDishes();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendGarnish() {
      this.formData.days = this.valueDays;
      this.axios
        .post(`${this.url}/garnishes/`, this.formData)
        .then(() => {
          this.toast.success("Гарнир успешно добавлен", {
            timeout: 3000,
            position: "top-left",
          });
          this.getGarnishes();
          this.closeActionBar();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateGarnish(id) {
      this.formData.days = this.valueDays;
      this.axios
        .put(`${this.url}/garnishes/${id}`, this.formData)
        .then(() => {
          this.toast.success("Гарнир успешно изменен", {
            timeout: 3000,
            position: "top-left",
          });
          this.getGarnishes();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteGarnish(id) {
      this.axios
        .delete(`${this.url}/garnishes/${id}`)
        .then(() => {
          this.toast.success("Гарнир успешно удален", {
            timeout: 3000,
            position: "top-left",
          });
          this.getGarnishes();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    openActionBar(actionType, postType, data) {
      this.activeBar = true;
      this.postType = postType;

      if (actionType === "edit") {
        this.formData = data;
        this.updateForm = true;

        this.formData.days.forEach((day, index) => {
          this.valueDays[index] = day.id;
        });
      }
    },

    closeActionBar() {
      this.updateForm = false;
      this.activeBar = false;
      this.formData = {};
      this.valueDays = [];
    },

    sortByDay(day, i) {
      this.activeClassDay = i;

      if (day.length) {
        this.sortedDishes = [];
        this.dishes.map((item) => {
          item.days.forEach((d) => {
            if (d.title === day) {
              this.sortedDishes.push(item);
            }
          });
        });
      } else {
        this.sortedDishes = this.dishes;
      }

      if (day.length) {
        this.sortedGarnishes = [];
        this.garnishes.map((item) => {
          item.days.forEach((d) => {
            if (d.title === day) {
              this.sortedGarnishes.push(item);
            }
          });
        });
      } else {
        this.sortedGarnishes = this.garnishes;
      }
      // this.dishes = this.filteredDishes.filter(item => {
      //     item.days = item.days.filter(d => d.title === day);
      //     return item.days.length;
      // });
    },
  },

  computed: {
    filteredDishes() {
      return this.sortedDishes;
    },
    filteredGarnishes() {
      return this.sortedGarnishes;
    },
  },
};
</script>


<style lang='scss' scoped>
$dark: #19191b;
$orange: #f6a108;

.logo {
  margin: 30px auto;
  width: 150px;
}

/*------------------------Content------------------------*/
.add-box {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.content {
  .filter-head {
    display: flex;
    justify-content: center;
  }

  .filter-head li {
    margin-right: 15px;
    padding: 8px 18px;
    background: #000;
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: 0.2s;

    &:hover,
    &.active {
      border: 2px solid #f6a108;
    }
  }

  .content-items {
    margin-top: 25px;

    .title-box-small {
      font-size: 20px;
      text-decoration: none;
    }
  }
}

.no-data {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.dishes-item {
  background: var(--dark);
  position: relative;
  padding: 10px 70px 10px 15px;
  margin: 15px 0;
  background: #202023;
  border-radius: 4px;
  overflow: hidden;
  min-height: 102px;

  &:after {
    content: "";
    position: absolute;
    top: 25px;
    left: 15px;
    width: calc(100% - 90px);
    border-bottom: 2px dotted #55555a;
  }

  .dishes-wrap {
    background: #202023;
  }

  .title {
    font-size: 22px;
  }

  .small-title {
    font-size: 17px;
    color: #eee;
  }

  .price {
    position: relative;
    font-size: 22px;
    background: inherit;
    padding-left: 15px;
    z-index: 1;
  }

  .info {
    position: relative;
    background: inherit;
    padding-right: 15px;
    z-index: 1;
  }

  .days {
    font-size: 14px;
    color: #a0a0a0;
    margin-top: 10px;

    .day-tag {
      background: #4e4431;
      padding: 2px 5px;
      margin-right: 5px;
      border-radius: 4px;
    }
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 46px;
    background: #000;

    .btn {
      height: 50%;
      width: 100%;
      padding: 0;
      text-align: center;
      border-radius: 0;
      background: #042300;
    }

    .btn-delete {
      background: #230000;
    }
  }
}

/*------------------------Garnish------------------------*/

.garnish-box {
  padding: 25px 0;
  border-top: 1px solid #404048;
}

.garnish-box .garnish-item {
  margin: 15px 0;
}

.garnish-box .garnish-item .title {
  font-size: 22px;
}

.garnish-box .dishes-item:after {
  border-bottom: 0;
}

/*------------------------Forms and Form-Bar------------------------*/

.action-bar {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background: #000;
  padding: 30px 20px;
  z-index: 999;
  transition: 0.1s;
}

.action-bar.active {
  visibility: visible;
  right: 0;
}

.action-bar .close-bar {
  display: none;
  position: absolute;
  top: 15px;
  left: -30px;
  cursor: pointer;
  transition: 0.2s;
}

.action-bar.active .close-bar {
  display: block;
}

.action-bar .close-bar:hover {
  opacity: 0.6;
}

.overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #272727;
  opacity: 0;
  z-index: 998;
  transition: 0.2s;
}

.overlay.active {
  visibility: visible;
  opacity: 0.6;
}

.title-head {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 30px;
}

.form label {
  display: block;
  color: #bfbfbf;
  margin-bottom: 8px;
}

.form .form-item input {
  background: #2a2a2d;
  height: 46px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  padding: 10px 15px;
  transition: 0.2s;
}

.form .form-item input:hover,
.form .form-item input:focus {
  border: 1px solid #5f5f67;
}

.form .form-item.form-error {
  border: 1px solid red;
}

.form-item,
.form-select {
  margin-bottom: 15px;
}

.form-action {
  padding-top: 15px;
  border-top: 1px solid #464646;
}

.custom-checkbox {
  display: flex;
  flex-wrap: wrap;
}

.custom-checkbox .checkbox-item {
  margin-right: 8px;
}

.custom-checkbox .checkbox-item [type="checkbox"]:checked,
.custom-checkbox .checkbox-item [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.custom-checkbox .checkbox-item .checkbox:checked + label,
.custom-checkbox .checkbox-item .checkbox:not(:checked) + label {
  position: relative;
  padding: 6px 15px;
  font-size: 13px;
  letter-spacing: 1px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  background: #2a2a2d;
  transition: 0.3s;
}

.custom-checkbox .checkbox-item .checkbox:not(:checked) + label:hover,
.custom-checkbox .checkbox-item .checkbox:checked + label {
  background: #39395d;
}
</style>
